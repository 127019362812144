exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-evenementen-js": () => import("./../../../src/pages/evenementen.js" /* webpackChunkName: "component---src-pages-evenementen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-manege-js": () => import("./../../../src/pages/manege.js" /* webpackChunkName: "component---src-pages-manege-js" */),
  "component---src-pages-openingstijden-js": () => import("./../../../src/pages/openingstijden.js" /* webpackChunkName: "component---src-pages-openingstijden-js" */),
  "component---src-pages-pension-js": () => import("./../../../src/pages/pension.js" /* webpackChunkName: "component---src-pages-pension-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-restaurant-js": () => import("./../../../src/pages/restaurant.js" /* webpackChunkName: "component---src-pages-restaurant-js" */),
  "component---src-pages-tarieven-js": () => import("./../../../src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */),
  "component---src-pages-verkooppaarden-js": () => import("./../../../src/pages/verkooppaarden.js" /* webpackChunkName: "component---src-pages-verkooppaarden-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-evenement-js": () => import("./../../../src/templates/evenement.js" /* webpackChunkName: "component---src-templates-evenement-js" */),
  "component---src-templates-paard-js": () => import("./../../../src/templates/paard.js" /* webpackChunkName: "component---src-templates-paard-js" */)
}

